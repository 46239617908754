.sellCrypto {
  position: relative;
  .background {
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: -1;
  }
}

.orbsBackground {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 125%;
  height: 100%;
  object-fit: contain;
  z-index: -1;
}

.leftCircle {
  background-color: #695a90;
  width: calc(768px - 250px);
  height: calc(768px - 250px);
  position: absolute;
  left: 20%;
  top: 7%;
  transform: translateX(-50%);
  border-radius: 50%;
  filter: blur(170px);
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(170px);
    -webkit-backdrop-filter: blur(170px);
  }
}
.rightCircle {
  background-color: #5a8690;
  width: calc(768px - 100px);
  height: calc(768px - 100px);
  position: absolute;
  left: 66%;
  top: 10%;
  transform: translateX(-50%);
  border-radius: 50%;
  filter: blur(140px);
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(140px);
    -webkit-backdrop-filter: blur(140px);
  }
}
.medias {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
  margin-bottom: 25px;
  .media {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #57595d 0% 0% no-repeat padding-box;
    border-radius: 10px;
    margin-left: 18px;
    color: #9aa3a7;
    &:hover {
      background: var(--orange-main);
      color: #fff;
    }
  }
  .media:first-child {
    margin-left: 0;
  }
}
.text {
  text-align: center;
  font-size: 20px;
  color: #9aa3a7;
}

.circleLeftIost {
  background-color: #383838;
}

.circleRightIost {
  background-color: #2c2c2c;
}

.circleLeftOrbs {
  background-color: #b2c0e2;
}

.circleRightOrbs {
  width: 20%;
  background-color: #383838;
}

.circleLeftBull {
  background-color: #695a90;
}

.circleRightBull {
  background-color: #5a8690;
}

.circleLeftDove {
  background-color: #3786c7;
}
.circleRightDove {
  background-color: #699099;
}

.circleLeftAvax {
  width: calc(960px - 250px);
  background-color: hsl(351, 67%, 50%);
}
.circleRightAvax {
  width: 20%;
  background-color: #383838;
}

.circleLeftSWEAT {
  background-color: #ae3268;
  top: 5%;
}

.circleRightSWEAT {
  background-color: #0c051d;
}

.circleLeftATOMCampaign {
  background-color: #4b4b4b;
  top: 5%;
}

.circleRightATOMCampaign {
  background-color: #292929;
}

.circleLeftATOM {
  background-color: #1b1e36;
}

.circleRightATOM {
  background-color: #6f7390;
}

.circleLeftMobile {
  background-color: #4b4b4b;
  top: 15%;
}

.circleRightMobile {
  background-color: #4b4b4b;
  top: 0;
}

.circleLeftIOTX {
  background-color: #3f5263;
}

.circleRightIOTX {
  background-color: #006349;
}

.circleLeftSOL {
  background-color: #706198;
}

.circleRightSOL {
  background-color: #388897;
}

.circleLeftOrbs {
  background-color: #03f1ea;
  filter: blur(200px);
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(200px);
    -webkit-backdrop-filter: blur(200px);
  }
}

.circleRightOrbs {
  background-color: #515c8f;
  left: 70%;
  top: 10%;
  transform: translateX(-50%);
  border-radius: 50%;
  filter: blur(160px);
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(160px);
    -webkit-backdrop-filter: blur(160px);
  }
}
