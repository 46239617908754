.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: 0px;
  margin-top: -200px;
  width: 100%;
  z-index: 1;
  & > img {
    width: auto;
    max-width: 100%;
  }
}

.actions {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 50px;
  margin-top: -250px;
  margin-right: 180px;

  h1 {
    font-size: 33px;
    font-weight: 900;
    line-height: 43px;
    color: #fff;
    margin-bottom: 0;
    margin-right: 36px;
  }

  p {
    color: #06d4d2;
    font-size: 24px;
    font-weight: 900;
    margin-top: 24px;
    line-height: 150%;
    text-shadow: 0px 0px 20px #06d4d2;
    span {
      font-size: 36px;
    }
  }
}

@media (max-width: 1200px) {
  .actions {
    margin-top: -200px;
  }
  .logo {
    margin-left: -50px;
  }
}

@media (max-width: 1024px) {
  .logo {
    margin-top: -100px;
  }

  .actions {
    margin-top: -100px;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
    > div {
      flex-direction: column;
      h1 {
        margin-right: 0;
      }
    }
    p {
      margin-top: 10px;
      font-size: 22px;
      text-align: center;
      span {
        font-size: 32px;
      }
    }
  }
}

@media (max-width: 768px) {
  .logo {
    margin-top: 0;
  }
  .actions {
    margin-top: 0;
  }
}
