.wrapTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 56px;
  .title {
    font-size: 45px;
    line-height: 56px;
    color: #fff;
    font-weight: 900;
  }
  h2 {
    margin: 0;
  }
  .viewLink {
    display: flex;
    align-items: center;
    color: #bfc1c3;
  }
}
@media (max-width: 576px) {
  .wrapTitle {
    flex-direction: column;
    .title {
      font-size: 34px;
      line-height: 40px;
    }
  }
}
