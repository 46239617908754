/* .introWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
/* .searchIcon {
  position: absolute;
  right: 10px;
  top: 30%;
} */

.btnWatch:hover,
.btnWatch:focus {
  background: transparent;
}

.btnWatch::before {
  background: transparent;
}

.searchInput {
  margin-right: 8px;
  padding-right: 0px;
}

.pairText:hover * {
  color: var(--orange-main) !important;
}

/* Remove antd table default styles */
.table :global .ant-table,
.table :global .ant-spin-container::after,
.table :global .ant-table-placeholder:hover > td {
  background: none !important;
}

.table :global .ant-table-expanded-row-fixed {
  width: 100% !important;
}

.table thead tr th::before,
.table thead tr th {
  background: none !important;
  color: #9aa3a7;
  border-bottom: none;
}

/* ------------------------------------------ */

/* Table styles */
.table tr:hover > td {
  color: var(--orange-main);
}

.table {
  margin-bottom: 5px;
}

.table table {
  border-spacing: 0px 10px !important;
}

.table tr td {
  padding: 0 !important;
  background: none !important;
  border: 2px solid #5f5f5f26;
  height: 65px;
  border-right-width: 0;
  border-left-width: 0;
}

.table tr td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left-width: 2px;
  padding-left: 15px !important;
}

.table tr th:first-child {
  padding-left: 25px !important;
}

.table tr td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-right-width: 2px;
  padding-right: 16px !important;
}
