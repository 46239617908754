.root {
  padding: 0 75px;
  margin-bottom: 152px;
}

.title {
  font-weight: 900 !important;
  font-size: 45px !important;
  line-height: 57px !important;
  margin-bottom: 9px !important;
}

.subtitle {
  font-size: 18px;
  line-height: 23px;
}

@media (max-width: 991px) {
  .title,
  .subtitle {
    text-align: center;
  }
}
@media (max-width: 768px) {
  .root {
    padding: 0px 15px;
  }
}
.background {
  background: #ffffff0d 0% 0% no-repeat padding-box;
}
