.titles {
  display: flex;
  flex-direction: column;
  & > span:first-child {
    font-size: 45px;
    font-weight: 900;
    color: var(--orange-main);
  }
  & > span:nth-child(2) {
    font-size: 72px;
    font-weight: 600;
    margin-bottom: 17px;
    line-height: 79px;
    line-height: 79px;
  }
  .wrapButtonTrade {
    position: relative;
    // margin: 0 auto;
    margin-top: 14px;
    width: 341px;
    span:nth-child(2) {
      display: none;
    }
    &:hover {
      span:nth-child(1) {
        display: none;
      }
      span:nth-child(2) {
        display: block;
      }
    }
    .btnAvax {
      display: flex;
      justify-content: flex-start;
      button {
        border-radius: 50px;
        font-size: 22px;
        color: #8b4500;
        padding: 15px 50px !important;
      }
      align-self: flex-end;
      transition: all 0.5s ease;
    }
  }
}
.titleNoButton {
  display: flex;
  justify-content: center;
  flex-direction: column;
  & > span:first-child {
    font-size: 45px;
    font-weight: 900;
    color: var(--orange-main);
  }
  & > span:nth-child(2) {
    font-size: 72px;
    font-weight: 600;
    margin-bottom: 17px;
    line-height: 79px;
    line-height: 79px;
  }
  .wrapButtonTrade {
    position: relative;
    width: 100%;
    display: flex;
  }
}

.label {
  font-size: 13px;
  line-height: 25px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #ffffff;
  letter-spacing: 0;
  opacity: 1;
  margin-bottom: 10px;
}

.button {
  background: #7c5c31 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 11px 12px;
  display: inline-flex;
  align-items: center;
  cursor: not-allowed;
  width: fit-content;
}

.btnClickable {
  cursor: pointer;
  transition: all 0.4s;
  &:hover {
    transform: scale(1.13);
  }
  &:active {
    transform: scale(0.88);
  }
}

@media (max-width: 1200px) {
  .titles {
    .wrapButtonTrade {
      .btnAvax {
        justify-content: center;
      }
    }
  }
}

@media (max-width: 992px) {
  .titles {
    align-items: center;
  }
  .titleNoButton {
    & > span:first-child {
      line-height: 57px;
      margin-bottom: 10px;
      font-size: 45px;
      font-weight: 900;
      color: var(--orange-main);
    }
  }
  .wrapButtonTrade {
    margin: 0;
    .btnAvax {
      justify-content: center;
    }
  }
}

@media (max-width: 768px) {
  .wrapButtonTrade {
    margin: 0 auto;
  }
  .btnAvax {
  }
  .wrapButtonTrade {
    margin: 0;
  }
  .titleNoButton *,
  .titles * {
    text-align: center;
  }
  .titles {
    align-items: center;
  }
}

// @media (min-width: 768px) {
//   .titleNoButton {
//     & > span:first-child {
//       line-height: 57px;
//       margin-bottom: 10px;
//       font-size: 45px;
//       font-weight: 900;
//       color: var(--orange-main);
//     }
//   }
//   .titles {
//     .wrapButtonTrade {
//       margin: 0;
//       .btnAvax {
//         justify-content: start;
//       }
//     }
//   }
// }
