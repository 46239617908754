.banner {
  margin-top: 24px;
  position: relative;
  .background {
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: -1;
    .left {
      background-color: #695a90;
      width: calc(768px - 250px);
      height: calc(768px - 250px);
      position: absolute;
      left: 20%;
      top: 7%;
      transform: translateX(-50%);
      border-radius: 50%;
      filter: blur(170px);
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(170px);
        -webkit-backdrop-filter: blur(170px);
      }
    }
    .right {
      background-color: #5a8690;
      width: calc(768px - 100px);
      height: calc(768px - 100px);
      position: absolute;
      left: 70%;
      top: 10%;
      transform: translateX(-50%);
      border-radius: 50%;
      filter: blur(140px);
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(140px);
        -webkit-backdrop-filter: blur(140px);
      }
    }
  }
}

.wrapTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: 28px;
  line-height: 37px;
  span {
    color: #ffba00;
  }
  margin-bottom: 44px;
}
.wrapImage {
  display: flex;
  justify-content: center;
  > * {
    width: 100%;
  }
}
.registerWrapper {
  padding: 4px;
  background: #ffffff26 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000026;
  border: 2px solid #5f5f5f26;
  width: 90%;
  border-radius: 40px;
  height: 68px;
  display: flex;
  justify-content: space-between;
  input {
    padding-left: 20px;
    // width: 330px;
    flex: 1;
    height: 100%;
    padding: 10px 16px;
    border: 0;
    background: transparent;
    border-radius: 40px;
    border-top-style: none;
    font-size: 16px;
    display: block;
  }
  input::-webkit-input-placeholder {
    // color: #999;
    font-weight: normal;
    font-size: 16px;
    color: #c5c6c8;
  }
  input:focus {
    outline: 0;
    background: none;
  }
}

.btn {
  display: flex;
  justify-self: flex-end;
  align-self: flex-end;
  button {
    border-radius: 50px;
    font-size: 22px;
    color: #8b4500;
    width: 100%;
    max-width: 254px;
  }
  align-self: flex-end;
  transition: all 0.5s ease;
  //    transform: translate3d(0, 0, 0);
  span:nth-child(2) {
    display: none;
  }
  &:hover {
    width: 100%;
    max-width: 200px;
    span:nth-child(1) {
      display: none;
    }
    span:nth-child(2) {
      display: block;
    }
  }
}
@media (max-width: 992px) {
  .wrapImage {
    img {
      margin-top: 24px;
      // width: 90%;
    }
  }
  .registerWrapper {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .registerWrapper {
    height: 50px;
    input {
      width: 70%;
    }
  }

  .btn {
    width: 30%;
    button {
      height: 40px !important;
      padding: 0 !important;
      span {
        font-size: 18px !important;
      }
    }
  }
}
