.deviceTitle {
  & > span:nth-child(3) {
    color: var(--orange-main);
  }
}
@media (max-width: 992px) {
  .deviceTitle {
    font-size: 32px !important;
    line-height: 100% !important;
  }
}
@media (max-width: 576px) {
  .deviceTitle {
    font-size: 24px !important;
    line-height: 100% !important;
  }
}
