.largeItem {
  padding: 10px;
  justify-content: space-around;
  align-items: center;
  display: flex;
  border: none;
  background: transparent;
  width: 100%;
  opacity: 1;
  gap: 20px;
  gap: 20px;
  .wrapImage {
    img {
      width: 100%;
      height: 100%;
      max-height: 400px;
      object-fit: contain;
    }
  }
  .wrapTitle {
    margin-left: 12px;
    .mainTitle {
      font-weight: 900;
      color: #fff;
      font-size: 42px;
      line-height: 110%;
    }
    .subTitle {
      font-size: 28px;
      line-height: 37px;
    }
  }
}

@media (max-width: 1200px) {
  .largeItem {
    .wrapTitle {
      .mainTitle {
        font-size: 38px;
      }
      .subTitle {
        font-size: 26px;
        line-height: 32px;
      }
    }
  }
}
@media (max-width: 992px) {
  .largeItem {
    .wrapTitle {
      .mainTitle {
        font-size: 22px;
        line-height: 30px;
      }
      .subTitle {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}

@media (max-width: 576px) {
  .largeItem {
    .wrapTitle {
      .mainTitle {
        font-size: 18px;
        line-height: 22px;
      }
      .subTitle {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
