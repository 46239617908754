.markets {
  margin-bottom: 0;
  position: relative;
}
.content {
  width: 100%;
  margin-left: 0 !important;
  .wrapImage {
    display: flex;
    justify-content: center;
    margin-bottom: 61px;
    max-height: 256px;
    img {
      border: 4px solid rgba(112, 112, 112, 0.25);
      border-radius: 12px;
      object-fit: cover;
      width: 100%;
    }
  }
  .breakNew {
    display: flex;
  }
  .wrapNews {
    .news {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 61px;
      .new {
        color: #fff;
        font-size: 18px;
        width: 100%;
        padding: 10px 16px;
        margin-bottom: 8px;
        background: #22242a 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000026;
        border: 2px solid #5f5f5f26;
        border-radius: 10px;
        & > div:first-child {
          font-weight: 600;
        }
      }
    }
    .wrapButton {
      display: flex;
      justify-content: center;
    }
  }
}
.title {
  text-align: center;
  font-size: 45px;
  font-weight: 900;
  width: 100%;
  margin-bottom: 57px;
}

.background {
  background: #ffffff0d 0% 0% no-repeat padding-box;
  padding: 40px 0;
}
.breakNew {
  color: #fff;
  .breakImage {
    margin-right: 31px;
  }
  .breakTitle {
    font-size: 18px;
    & > div:first-child {
      font-weight: 600;
    }
  }
}
.smallText {
  position: absolute;
  top: -14px;
  font-size: 18px;
}
.wrapButtonTrade {
  position: relative;
  bottom: -70px;
  width: 100%;
  display: flex;
  justify-content: center;
  .btnAvax {
    display: flex;
    justify-content: center;
    button {
      border-radius: 50px;
      font-size: 22px;
      color: #8b4500;
    }
    margin-top: 28px;
    align-self: flex-end;
    transition: all 0.5s ease;
    width: 270px;
    //    transform: translate3d(0, 0, 0);
    span:nth-child(2) {
      display: none;
    }
    &:hover {
      &::after {
        content: '';
        position: absolute;
        // left: -50px;
        top: 0;
        height: 50px;
        width: 100%;
        box-sizing: border-box;
        z-index: -1;
      }
      width: 200px;
      span:nth-child(1) {
        display: none;
      }
      span:nth-child(2) {
        display: block;
      }
    }
  }
}
@media (max-width: 992px) {
  .breakNew {
    align-items: center;
    justify-content: center;
    margin-bottom: 34px;
  }
  .wrapNew {
    text-align: center;
  }
}
