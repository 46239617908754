.wrapper {
  display: inline-block;
  line-height: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.text {
  display: inline-block;
  height: 14px;
}
.icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: relative;
  background: rgba(255, 255, 255, 0.4);
  margin-left: 5px;
}
.icon > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 11px;
  color: rgba(0, 0, 0, 0.6);
}

.faSortUp > * {
  transform: translate(-50%, calc(-50% + 2px));
}

.faSortDown > * {
  transform: translate(-50%, calc(-50% - 2px));
}
