.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #40454f26 0% 0% no-repeat padding-box;
  border: 2px solid #5f5f5f40;
  border-radius: 10px;
  padding: 5px 12px 3px 12px;
  max-height: 44px;
  label {
    font-weight: bold;
    font-size: 16px;
    white-space: nowrap;
  }
  input {
    border: none;
    outline: none;
    background: transparent;
    text-align: right;
    width: 100%;
    color: var(--tint-3);
    font-size: 18px;
  }
  span {
    font-size: 16px;
    color: var(--tint-3);
    margin-bottom: -3px;
    margin-left: 4px;
  }
}
