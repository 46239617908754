.wrapImage {
  img {
    position: relative;
    width: 200%;
    right: 50%;
    z-index: 0;
  }
}
.wrapTitle {
  position: relative;
  top: 154px;
  font-size: 45px;
  font-weight: 900;
  width: 100%;
  text-align: center;
  z-index: 1;
  & > span:first-child {
    color: var(--orange-main);
  }
}
.wrapTitle.paddingTop {
  margin-top: 46px;
}
.wrapButton.paddingBottom {
  margin-bottom: 46px;
}
.appDownload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .smallTitle {
    margin-bottom: 80px;
    text-align: center;
    font-size: 20px;
    & > div:first-child {
      font-weight: 600;
      color: #ffffff;
    }
    & > div:last-child {
      color: var(--orange-main);
    }
  }
  .wrapButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > a:first-child {
      margin-bottom: 20px;
    }
    .media:hover {
      color: #9aa3a7;
    }
  }
  .mediaMobile {
    width: 234px;
    padding: 6px 0px;
    border-radius: 8px;
    border: solid 4px var(--orange-main);
    transition: ease-in-out 0.3s;
    &:hover {
      opacity: 0.6;
      border-color: #9aa3a7;
    }
  }
}
@media (max-width: 1200px) {
  .wrapTitle {
    top: 0;
  }
}
@media (max-width: 992px) {
  .wrapImage {
    img {
      position: relative;
      width: 100%;
      right: 0;
    }
  }
}
