.wrapCarousel {
  margin-top: 156px;
  width: 100%;
  .topCarousel {
    margin-bottom: 60px;

    div[class~='slick-slide'] {
      opacity: 0;
    }
    div[class~='slick-active'] {
      opacity: 1;
    }
    div[class~='slick-track'] {
      display: flex;
      align-items: center;
    }
  }
}

.titles {
  .leftPanel {
    display: flex;
    justify-content: center;
    line-height: 56px;
  }
  display: flex;
  justify-content: center;
  flex-direction: column;
  & > span:first-child {
    font-size: 38px;
    color: var(--orange-main);
  }
  & > span:nth-child(2) {
    font-size: 72px;
    font-weight: 600;
    margin-bottom: 17px;
    line-height: 79px;
  }
  .wrapButtonTrade {
    position: relative;
    width: 100%;
    display: flex;
    .btnAvax {
      display: flex;
      button {
        border-radius: 50px;
        font-size: 22px;
        color: #8b4500;
        padding: 15px 50px !important;
      }
      align-self: flex-end;
      transition: all 0.5s ease;
      width: 270px;
      //    transform: translate3d(0, 0, 0);
      span:nth-child(2) {
        display: none;
      }
      &:hover {
        &::after {
          content: '';
          position: absolute;
          // left: -50px;
          top: 0;
          height: 50px;
          width: 100%;
          box-sizing: border-box;
          z-index: -1;
        }
        width: 200px;
        span:nth-child(1) {
          display: none;
        }
        span:nth-child(2) {
          display: block;
        }
      }
    }
  }
}
.item {
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #cccccc1a;
  border-radius: 12px;
  background: #22242a 0% 0% no-repeat padding-box;
  opacity: 0.5;

  height: 152px;
  padding: 22px;
  cursor: pointer;
  .wrapImage {
    overflow: hidden;
    overflow: hidden;
    img {
      width: 106px;
      max-width: 100%;
    }
  }
  .wrapTitle {
    margin-left: 10px;
    .mainTitle {
      color: #fff;
      font-weight: 600;
      font-size: 17px;
    }
    .subTitle {
      font-size: 11px;
    }
  }
}
.largeItem {
  justify-content: center;
  align-items: center;
  display: flex;
  border: none;
  background: transparent;
  width: 100%;
  opacity: 1;
  gap: 20px;
  .wrapImage {
    img {
      width: 100%;
      max-width: 262px;
      object-fit: contain;
    }
  }
  .wrapTitle {
    margin-left: 12p;
    .mainTitle {
      font-weight: 600;
      color: #fff;
      font-size: 45px;
      line-height: 43px;
    }
    .subTitle {
      font-size: 28px;
      line-height: 37px;
    }
  }
}
.bottomCarousel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.activeItem {
  background: rgba(34, 36, 42, 0.5);
  opacity: 1;
}
.carousel {
  width: calc(100% - 32px);
  > div:first-child {
    // div[class~='slick-track'] {
    //   > div:not([class~='slick-active']) {
    //     opacity: 0;
    //   }
    // }
    div[class~='slick-slide'] {
      padding: 0 12px;
      box-sizing: border-box;
      pointer-events: unset !important;
      // margin:0 27px;
    }

    ul:nth-child(2) {
      bottom: -78px;
      li {
        width: 10px;
        height: 10px;
        background: var(--dark-background);
        border-radius: 50%;
        border: 2px solid rgb(204 204 204 / 10%);
        cursor: pointer;
      }
      li[class='slick-active'] {
        background: var(--orange-main);
      }
      button {
        width: 14px;
        height: 14px;
        opacity: 0;
      }
    }
    .slick-dots {
      position: static;
      margin-top: 20px;

      li button {
        height: 200px !important;
        border-radius: 4px !important;
        background-color: gray !important;
      }
    }
  }
}

.title {
  background-color: antiquewhite;
}

@media (max-width: 1200px) {
  .item {
    padding: 12px;
    height: 230px;
    .wrapTitle {
      margin-left: 0;
      .mainTitle {
        font-size: 15px;
      }
      .subTitle {
        font-size: 10px;
      }
    }
  }
  .largeItem {
    gap: 10px;

    .wrapImage {
      img {
        width: 228px;
      }
    }
    .wrapTitle {
      margin-left: 0;
      .mainTitle {
        font-size: 30px;
        line-height: 38px;
      }
      .subTitle {
        font-size: 22px;
      }
    }
  }
  .item {
    flex-direction: column;
    .wrapTitle {
      margin-top: 8px;
      text-align: center;
    }
  }
  .titles {
    align-items: center;
    text-align: center;
    .wrapButtonTrade {
      left: 0;
    }
  }
}
@media (max-width: 992px) {
  .largeItem {
    margin-top: 32px;
  }
  .wrapCarousel {
    margin-top: 32px;
    .wrapMainContent {
      margin-bottom: 32px;
      .item {
        height: 230px;
      }
    }
  }
  .wrapButtonTrade {
    justify-content: center;
    .btnAvax {
      justify-content: center;
    }
  }
}
@media (max-width: 576px) {
  .topCarousel {
    width: calc(100%);
    div[class~='slick-track'] {
      height: 100%;
    }
  }

  .largeItem {
    height: inherit;
    height: inherit;
    .wrapImage {
      img {
        height: 160px;
        width: 130px;
      }
    }
    .wrapTitle {
      .mainTitle {
        font-size: 16px;
        line-height: 22px;
      }
      .subTitle {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  .carousel {
    .item {
      padding: 8px;
      height: 140px;
      .wrapImage {
        img {
          width: 60px;
        }
      }
      .wrapTitle {
        .mainTitle {
          font-size: 12px;
        }
        .subTitle {
          display: none;
          font-size: 8px;
        }
      }
    }
  }
}
@media (max-width: 465px) {
  .carousel {
    .item {
      padding: 4px;
      height: 120px;
      .wrapImage {
        img {
          width: 40px;
        }
      }
      .wrapTitle {
        .mainTitle {
          font-size: 8px;
        }
        .subTitle {
          display: none;
        }
      }
    }
  }
}
