.wrapStepInfo {
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  .wrapTitle {
    display: flex;
    align-items: center;
    .largeNum {
      font-size: 226px;
      line-height: 226px;
      margin-bottom: 28px;
      font-weight: 900;
      color: var(--orange-main);
      position: relative;
      top: 16px;
    }
    .textRights {
      display: flex;
      flex-direction: column;
      .text {
        font-weight: 600;
        font-size: 45px;
      }
    }
  }
  .wrapButtonTrade {
    position: relative;
    width: 100%;
    display: flex;
    .btnAvax {
      display: flex;
      button {
        border-radius: 50px;
        font-size: 22px;
        color: #8b4500;
        padding: 15px 50px !important;
      }
      margin-top: 28px;
      align-self: flex-end;
      transition: all 0.5s ease;
      width: 270px;
      //    transform: translate3d(0, 0, 0);
      span:nth-child(2) {
        display: none;
      }
      &:hover {
        &::after {
          content: '';
          position: absolute;
          // left: -50px;
          top: 0;
          height: 50px;
          width: 100%;
          box-sizing: border-box;
          z-index: -1;
        }
        width: 200px;
        span:nth-child(1) {
          display: none;
        }
        span:nth-child(2) {
          display: block;
        }
      }
    }
  }
  .stepInfo {
    display: flex;
    flex-direction: column;
    .step {
      display: flex;
      margin-bottom: 40px;
      .number {
        color: var(--orange-main);
        font-size: 33px;
        font-weight: 600;
        position: relative;
        top: -5px;
        margin-right: 5px;
      }
      .wrapText {
        display: flex;
        flex-direction: column;
        font-size: 18px;
        .smallTitle {
          font-weight: 600;
        }
      }
    }
  }
}
.wrapStepImage {
  .wrapStepInfo {
    align-items: center;
  }
  .stepImage {
    position: relative;
    margin-bottom: -80px;
    .middleNumber {
      font-size: 144px;
      font-weight: 900;
      margin: 0;
      margin-bottom: -132px;

      position: relative;
      right: 40px;
    }
    .wrapImage {
      width: 273px;
      height: 189px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      background: #40454f26 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px #00000026;
      border: 2px solid #cccccc1a;
      border-radius: 10px;
      opacity: 1;
      backdrop-filter: blur(2px);
      -webkit-backdrop-filter: blur(2px);
      font-size: 20px;
      & > div:nth-child(2) {
        margin-top: 7px;
      }
      img {
        width: 73px;
      }
      .mainText {
        color: var(--orange-main);
        font-size: 16px;
      }
      .subText {
        font-size: 16px;
      }
      div:nth-child(2) {
        color: var(--orange-main);
      }
    }
  }
  .stepImage:nth-child(2) {
    margin-left: 337px;
  }
  .stepImage:nth-child(3) {
    top: -36px;
    left: 40px;
  }
}

@media (max-width: 1366px) {
  .wrapStepInfo {
    padding-right: 0;
  }
  .wrapStepImage {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    .stepImage {
      margin: 0;
    }
    .stepImage:nth-child(2) {
      margin-left: 0px;
    }
    .stepImage:nth-child(3) {
      top: 0;
      left: 0;
    }
  }
}
@media (max-width: 992px) {
  .wrapStepImage {
    align-items: flex-start;
    padding-left: 40px;
    .stepImage {
      width: 100%;
      padding: 0 10%;
      .wrapImage {
        width: 100%;
      }
    }
  }
}
@media (max-width: 768px) {
  .wrapStepInfo {
    .wrapTitle {
      justify-content: center;
    }
  }
  .wrapStepImage {
    align-items: center;
  }
}
