.features {
  .wrapTitle {
    width: 100%;
    text-align: center;
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 54px;
    margin-top: 40px;
    line-height: 58px;
    & > div:first-child {
      font-weight: 900;
      font-size: 45px;
      color: var(--orange-main);
    }
  }
  .wrapFeature {
    text-align: center;
    .wrapImage {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: rgba(87, 87, 87, 0.25) 0% 0% no-repeat padding-box;
      height: 256px;

      border: 2px solid rgba(112, 112, 112, 0.25);
      border-radius: 25px;
      padding: 48px 40px;
      margin-bottom: 25px;
      img {
        width: 100px;
        margin-bottom: 12px;
      }
      .smallTitle {
        text-align: center;
        font-size: 20px;
        color: var(--orange-main);
        font-weight: 600;
      }
    }
    .description {
      font-size: 14px;
    }
    .wrapButton {
      display: flex;
      justify-content: center;
      margin-top: 25px;
      button {
        border: none;
        color: #fff !important;
        background: #4192ff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000026;
        border-radius: 50px;
        padding: 11px 36px;
      }
    }
  }
  .wrapButtonTrade {
    position: relative;
    bottom: -28px;
    margin: 0 auto;
    width: 341px;
    display: flex;
    justify-content: center;
    span:nth-child(2) {
      display: none;
    }
    &:hover {
      span:nth-child(1) {
        display: none;
      }
      span:nth-child(2) {
        display: block;
      }
    }
    .btnAvax {
      display: flex;
      justify-content: center;
      button {
        border-radius: 50px;
        font-size: 22px;
        color: #8b4500;
        padding: 15px 50px !important;
      }
      margin-top: 28px;
      align-self: flex-end;
      transition: all 0.5s ease;
      width: 270px;
    }
  }
}
