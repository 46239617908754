.title {
  text-align: center;
  font-size: 45px;
  font-weight: 900;
  width: 100%;
  margin-bottom: 110px;
  margin-top: 156px;
}
.wrapImage {
  display: flex;
  justify-content: center;
  img {
    border: 4px solid rgba(112, 112, 112, 0.25);
    border-radius: 12px;
    object-fit: cover;
    width: 100%;
  }
}
.content {
  font-size: 18px;
  padding-right: 25px;
  .btnIost {
    font-size: 22px;
  }
  .smallTitle {
    font-weight: 600;
    color: var(--orange-main);
    margin-bottom: 32px;
  }
  .linkRequest {
    font-size: 18px;
    color: #fff;
    text-decoration: underline;
    display: block;
    margin-top: 24px;
    padding-left: 18px;
  }
  .description {
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .content {
    padding: 0;
    text-align: center;
    margin-bottom: 25px;
  }
  .wrapImage {
    img {
      width: 80%;
    }
  }
}
@media (max-width: 576px) {
  .wrapImage {
    img {
      width: 90%;
    }
  }
}
