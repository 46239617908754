.titleSweat {
  font-size: 40px;
  & > span:nth-child(2) {
    color: #ff0f74;
    margin-left: 8px;
    font-size: 80px;
  }
  & > span:nth-child(5) {
    font-size: 16px;
    margin-left: 8px;
    line-height: 100%;
    font-weight: 300;
    white-space: nowrap;
  }
}
@media (max-width: 1200px) {
  .titleSweat {
    font-size: 38px;
    & > span:nth-child(2) {
      font-size: 56px;
      margin-left: 22px;
    }
    & > span:nth-child(5) {
      font-size: 16px;
      display: block;
      margin: 0;
    }
  }
}

@media (max-width: 991px) {
  .titleSweat {
    font-size: 38px;
    & > span:nth-child(2) {
      font-size: 56px;
      margin-left: 22px;
    }
    & > span:nth-child(5) {
      display: unset;
      margin-left: 8px;
    }
  }
}
@media (max-width: 576px) {
  .titleSweat {
    & > span:nth-child(2) {
      font-size: 24px;
      margin-left: 10px;
    }
    & > span:nth-child(5) {
      white-space: unset;
      font-size: 12px;
      margin-left: 8px;
    }
  }
}
